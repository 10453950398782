import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='My Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      At IzzaTilila, I'm committed to providing accurate, insightful, and concise book summaries, and exploring key theories across psychology, philosophy, sociology, and economics. I believe in making learning accessible and enjoyable for everyone. I'll listen to my users' feedback and continually improve my app to meet their evolving needs. Join me in a community of passionate lifelong learners.
      </Text>
    </Card>
  </Section>
)

export default Commitment
