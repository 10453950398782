import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader="Thank you for visiting IzzaTilila, your source for insightful and concise summaries of books across psychology, philosophy, sociology, and economics. I'm dedicated to making learning accessible and enjoyable for everyone, regardless of their background or expertise. If you have any questions, feedback, or concerns, i'd love to hear from you. Please fill out the contact form below and i'll get back to you as soon as possible. Thank you for being a part of the IzzaTilila community!"
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
