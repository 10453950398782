import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const ContactInfo = () => {
  const { email } = useSiteMetadata()

  return (
    <Section aside title='Advertise With Us'>
      <Card variant='paper'>
        <Text variant='p'>Advertise with me and reach curious minds worldwide!</Text>
        <Text variant='p'>
          Contact me now to advertise on my website!
        </Text>
        {email && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo
